html,
body,
#root {
  height: 100% !important;
  width: 100%;
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

body {
  color: #666;
  font-size: 62.5%;
}

p,
a,
div {
  font-size: 16px;
}

a {
  color: #666;
  text-decoration: none;
}

a svg-inline--fa {
  margin-right: 5px;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

ul li {
  padding: 0;
  margin: 0;
}

input[type='text'] {
  box-sizing: border-box;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
  width: 100%;
  padding: 7px 10px;
}

/**
Temporary fix to overwrite and display the default style of the checkbox
to prevent issues with the custom component and react/fontawesome missing icons
 */
input[type="checkbox"] {
  appearance: checkbox !important;
  -webkit-appearance: checkbox !important;
  -moz-appearance: checkbox !important;
  margin: 0 0 0 16px !important
}
input[type="checkbox"]::before {
  content: none !important;
}
/**
Ends temporary react/fontawesome fix
 */

.link,
.link-button {
  color: #449df5;
  font-family: Raleway;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.75px;
  line-height: 14px;
  text-align: center;
  text-decoration: none;
  padding: 0;
}

.link:active,
.link-button:active,
.link:hover,
.link-button:hover {
  text-decoration: none;
}

* {
  font-family: 'Raleway', sans-serif;
}

.form-group {
  text-align: left;
  margin: 12px 0;
}

.form-group:first-child {
  margin-top: 0;
}

.form-group > label {
  color: #5361fd;
  font-family: Raleway;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.75px;
  line-height: 14px;
  text-transform: uppercase;
  display: block;
}

.form-group > input {
  height: 38px;
  width: 100%;
  margin: 10px 0;
  border-radius: 4px;
  border: solid 1px #ccc;
  box-sizing: border-box;
}

form-group .select-role {
  margin: 10px 0;
}

/* Overriding the center-align property from the styleguide */
.solid-provider-select {
  text-align: left !important;
}

.solid-provider-select .option {
  padding-left: 10px !important;
}

